import { isNothing, Utility } from './Ridingazua.Utility';
import { Resources, TextResources } from '../front/Ridingazua.Resources';
import { Log } from './Ridingazua.Log';
import { Statics } from './Ridingazua.Statics';
import dateformat from 'dateformat';
import * as Hangul from 'hangul-js';
import { sprintf } from 'sprintf-js';

export class User {
    id: number;
    name?: string;
    email?: string;
    nick?: string;
    accountType?: UserAccountType;
    isSuperuser?: boolean = false;
    isAdManager?: boolean = false;

    static fromJson(json: any): User | null {
        if (!json) {
            return null;
        }

        let user = new User();
        user.id = json['id'];
        user.name = json['name'];
        user.email = json['email'];
        user.nick = json['nick'];
        user.accountType = json['accountType'];
        user.isSuperuser = json['isSuperuser'];
        user.isAdManager = json['isAdManager'];

        return user;
    }

    toJson(): any {
        let json = {
            id: this.id,
            name: this.name,
            email: this.email,
            nick: this.nick,
            accountType: this.accountType,
        };

        if (this.isSuperuser) {
            json['isSuperuser'] = this.isSuperuser;
        }

        if (this.isSuperuser || this.isAdManager) {
            json['isAdManager'] = this.isAdManager;
        }

        return json;
    }

    clone(): User {
        return User.fromJson(this.toJson());
    }

    /**
     * 민감한 개인정보 제거
     */
    removePrivateInfo() {
        this.email = null;
        this.name = null;
        this.isSuperuser = null;
        this.isAdManager = null;
    }
}

export enum UserAccountType {
    FACEBOOK = 1,
    KAKAO = 2,
    GOOGLE = 3
}

export enum CoursePermission {
    LINK_PUBLIC_READ_ONLY = 0, // 링크가 있는 사람만이 접근 가능(읽기 전용)
    PRIVATE = 1, // 비공개
    ALL_PUBLIC_READ_ONLY = 2, // 모두에게 공개될 수 있음(읽기 전용)
}

export class Course {
    id?: number;
    userId?: number;
    name?: string;
    pathName?: string;
    createdTimestamp?: number;
    modifiedTimestamp?: number;
    permission: CoursePermission = Course.defaultCoursePermission;
    sections: Section[] = [];
    tags: string[] = [];
    description: string;
    lengthMeter = 0;
    totalElevationGain = 0;
    extra: CourseExtra = new CourseExtra();

    /**
     * 작성자 정보
     */
    user?: User;

    static maxTagLength = 100;
    static maxDescriptionLength = 1000;

    constructor() { }

    static fromJson(json: any): Course | null {
        if (!json) {
            return null;
        }

        let course = new Course();
        course.id = json['id'];
        course.userId = json['userId'];
        course.name = json['name'];
        course.pathName = json['pathName'];
        course.lengthMeter = json['lengthMeter'];
        course.totalElevationGain = json['totalElevationGain'];
        course.createdTimestamp = json['createdTimestamp'];
        course.modifiedTimestamp = json['modifiedTimestamp'];
        course.permission = json['permission'];
        course.tags = json['tags'] || [];
        course.description = json['description'];
        course.extra = CourseExtra.fromJson(json['extra']) || new CourseExtra();

        if (isNothing(course.permission)) {
            course.permission = Course.defaultCoursePermission;
        }

        let sectionElements = (json['sections'] || []) as any[];
        course.sections = sectionElements.map((element) => {
            return Section.fromJson(element);
        });

        let userJson = json['user'] as any;
        if (userJson) {
            course.user = User.fromJson(userJson);
        }

        if (course.sections && course.sections.length) {
            course.updateTotalValues();
        }

        return course;
    }

    toJson(): any {
        return {
            id: this.id,
            userId: this.userId,
            name: this.name,
            pathName: this.pathName,
            lengthMeter: this.lengthMeter,
            totalElevationGain: this.totalElevationGain,
            createdTimestamp: this.createdTimestamp,
            modifiedTimestamp: this.modifiedTimestamp,
            permission: this.permission,
            tags: this.tags || [],
            description: this.description,
            extra: this.extra,
            user: this.user?.toJson(),
            sections: this.sections.map((section) => {
                return section.toJson();
            }),
        };
    }

    clone(): Course {
        return Course.fromJson(this.toJson());
    }

    get isEmpty(): boolean {
        for (let section of this.sections) {
            if (section.points.length) {
                return false;
            }
        }
        return true;
    }

    /**
     * 코스를 새 코스로 저장하려할 경우, 이뤄져야할 처리들.
     * 서버에 저장이 되기 전에는 존재할 수 없는 값들을 없앤다.
     */
    setAsNew() {
        this.id = null;
        this.userId = null;
        this.pathName = null;
        this.createdTimestamp = null;
        this.modifiedTimestamp = null;
    }

    get idOrPathName(): number | string | null {
        let result: number | string = this.id;
        if (this.pathName && this.pathName.length) {
            result = this.pathName;
        }
        return result;
    }

    get titleToDocument(): string {
        return `${this.name || Resources.text.course_noname} - ${Resources.text.ridingazua_editor_title}`;
    }

    get firstSection(): Section | null {
        if (!this.sections || !this.sections.length) {
            return null;
        }

        return this.sections[0];
    }

    get lastSection(): Section | null {
        if (!this.sections || !this.sections.length) {
            return null;
        }

        return this.sections[this.sections.length - 1];
    }

    allPoints(): Point[] {
        let result: Point[] = [];
        this.sections.forEach((section) => {
            result = result.concat(section.points);
        });
        return result;
    }

    sectionOfPoint(point: Point): Section | null {
        for (let section of this.sections) {
            for (let sectioinPoint of section.points) {
                if (sectioinPoint === point) {
                    return section;
                }
            }
        }

        return null;
    }

    sectionByClientId(clientId: number): Section | null {
        for (let section of this.sections) {
            if (section.clientId == clientId) {
                return section;
            }
        }

        return null;
    }

    /**
     * sectionIndex, pointIndex, 총 거리, 누적상승고도 값을 계산하여 반영한다.
     */
    updateTotalValues() {
        let lengthMeter = 0;
        let totalElevationGain = 0;

        let sectionIndex = 0;
        this.sections.forEach((section) => {
            section.updateTotalValues();
            section.points.forEach((point) => {
                point.sectionIndex = sectionIndex;
            });
            sectionIndex++;
        });

        let allPoints = this.allPoints();
        let pointIndex = 0;
        allPoints.forEach((point) => {
            point.pointIndex = pointIndex;
            if (!point.elevation) {
                point.elevation = 0;
            }

            pointIndex++;
        });

        if (allPoints.length < 2) {
            this.lengthMeter = 0;
            this.totalElevationGain = 0;
        }

        if (allPoints.length) {
            allPoints[0].distanceFromCourseStart = 0;
        }

        for (let i = 1; i < allPoints.length; i++) {
            let point1 = allPoints[i - 1];
            let point2 = allPoints[i];
            let distance = Utility.distanceMeterBetween(point1.latitude, point1.longitude, point2.latitude, point2.longitude);
            point2.distanceFromCourseStart = (point1.distanceFromCourseStart || 0) + distance;
            lengthMeter += distance;

            let elevationGain = (point2.elevation || 0) - (point1.elevation || 0);
            if (elevationGain > 0) {
                totalElevationGain += elevationGain;
            }
        }

        this.lengthMeter = lengthMeter;
        this.totalElevationGain = totalElevationGain;
    }

    /**
     * 갑자기 튀는 구간이 있다면 그런 부분을 제거한다.
     */
    private removeUnexpectedPeak() {
        let points = this.allPoints();
        for (let i = 2; i < points.length; i++) {
            let pPoint = points[i - 2];
            let point = points[i - 1];
            let nPoint = points[i];

            let slope1 = (point.elevation - pPoint.elevation) / (point.distanceFromCourseStart - pPoint.distanceFromCourseStart);
            let slope2 = (nPoint.elevation - point.elevation) / (nPoint.distanceFromCourseStart - point.distanceFromCourseStart);
            if (Math.abs(slope1) > 0.3 && Math.abs(slope2) > 0.3) {
                if ((slope1 > 0 && slope2 < 0) || (slope1 < 0 && slope2 > 0)) {
                    let d1 = point.distanceFromCourseStart - pPoint.distanceFromCourseStart;
                    let d2 = nPoint.distanceFromCourseStart - pPoint.distanceFromCourseStart;
                    let ratio = d1 / d2;
                    let dElevation = nPoint.elevation - pPoint.elevation;
                    point.elevation = pPoint.elevation + dElevation * ratio;
                }
            }
        }
    }

    /**
     * 고도값을 보정한다.(smooth)
     * @param level
     */
    smoothElevations(level: number): boolean {
        let points = this.allPoints();
        let originalElevations = points.map((point) => {
            return point.originalElevation;
        });

        for (let originalElevation of originalElevations) {
            if (isNothing(originalElevation)) {
                return false;
            }
        }
        let smoothedElevations = Course.smoothElevations(originalElevations, level);
        for (let i = 0; i < points.length; i++) {
            points[i].elevation = smoothedElevations[i];
        }

        this.removeUnexpectedPeak();

        return true;
    }

    /**
     * 고도값을 보정한다.(smooth)
     * @param originalElevations
     * @param level
     */
    static smoothElevations(originalElevations: number[], level: number): number[] {
        if (originalElevations.length < level) {
            return originalElevations;
        }

        let result: number[] = [];

        let d = Math.floor(level / 2);
        let minIdx = d;
        let maxIdx = originalElevations.length - 1 - minIdx;

        for (let i = 0; i < originalElevations.length; i++) {
            let lIdx = 0;
            let rIdx = 0;
            if (i < minIdx) {
                lIdx = 0;
                rIdx = i * 2;
            } else if (i > maxIdx) {
                lIdx = i - (originalElevations.length - 1 - i);
                rIdx = originalElevations.length - 1;
            } else {
                lIdx = i - Math.floor(level / 2);
                rIdx = i + Math.floor(level / 2);
            }

            let c = 0;
            let sum = 0;
            for (let j = lIdx; j <= rIdx; j++, c++) {
                sum += originalElevations[j];
            }

            var avg = sum / c;
            result.push(avg);
        }

        return result;
    }

    /**
     * 클라임의 기준: 1000m 이상의 거리
     */
    private readonly minClimbLengthMeter = 1000;

    /**
     * 클라임의 기준: 평균 경사도 5% 이상
     */
    private readonly minClimbAvgSlope = 0.05;

    /**
     * Climb으로 판단되는 구간들을 반환
     */
    private findClimbs(): CourseRange[] {
        this.updateTotalValues();
        let allPoints = this.allPoints();
        let climbs: CourseRange[] = [];

        let cursor = 0;
        let summitCandidatePoint = null;
        while (cursor < allPoints.length) {
            let cursorPoint = allPoints[cursor];
            let detected = false;
            for (let j = cursor + 1; j < allPoints.length; j++) {
                let dPoint = allPoints[j];
                let distance = dPoint.distanceFromCourseStart - cursorPoint.distanceFromCourseStart;
                if (distance < this.minClimbLengthMeter) continue; // 이동거리가 최소 클라임 거리 이상인 구간만 체크

                let dElevation = dPoint.elevation - cursorPoint.elevation;
                let slope = dElevation / distance;
                if (slope > this.minClimbAvgSlope) {
                    // 이동거리간 평균경사도가 최소 클라임 평균경사도 이상이어야함
                    summitCandidatePoint = dPoint;
                    // summit으로 판단된 점 이후의 점을 탐색하여 최고도 지점을 탐색
                    // 최고도 지점 이후 500m 이상 이동간 상승하지 않을 경우 최고도 지점을 summit으로 지정
                    for (let k = j + 1; k < allPoints.length; k++) {
                        let kPoint = allPoints[k];
                        let isLastPoint = k >= allPoints.length - 1;
                        if (summitCandidatePoint.elevation < kPoint.elevation) {
                            // 더 높은 지점이 나오면 summit point 교체
                            summitCandidatePoint = kPoint;
                        } else {
                            // 최고도 후보 지점 이후 500m 이상 최고도 후보 이상 지점 없거나,
                            // 또는 마지막 지점까지 탐색이 되었다면, summit point로 등록.
                            let downhillDistance = kPoint.distanceFromCourseStart - summitCandidatePoint.distanceFromCourseStart;

                            if (downhillDistance > 500) {
                                climbs.push(CourseRange.createWithStartFinish(this, cursorPoint, summitCandidatePoint));
                                summitCandidatePoint = null;
                                detected = true;
                                cursor = k;
                                break;
                            }
                        }

                        if (isLastPoint) {
                            climbs.push(CourseRange.createWithStartFinish(this, cursorPoint, summitCandidatePoint));
                            summitCandidatePoint = null;
                            detected = true;
                            cursor = k;
                            break;
                        }
                    }
                } else {
                    break;
                }
                break;
            }
            if (!detected) {
                cursor++;
            }
        }

        return climbs;
    }

    /**
     * findClimbs 함수와 유사하지만, 하나로 봐도 무방할 것 같은 climb 두개가 근접해있을 경우, 합쳐서 처리한다.
     */
    findClimbsAdvanced(): CourseRange[] {
        let climbs = this.findClimbs();
        if (climbs.length < 2) {
            return climbs;
        }

        let result: CourseRange[] = [climbs[0]];
        for (let i = 1; i < climbs.length; i++) {
            let pClimb = result[result.length - 1];
            let climb = climbs[i];

            let mergedLength = climb.finishPoint.distanceFromCourseStart - pClimb.startPoint.distanceFromCourseStart;
            let sumOfLengths = climb.lengthMeter + pClimb.lengthMeter;
            let r = sumOfLengths / mergedLength; // 두 오르막의 길이를 각각 더한 값 / 두 오르막을 합쳤을 경우의 길이

            if (r > 0.8) {
                result.splice(result.length - 1, 1);

                let startPoint = pClimb.startPoint;
                let finishPoint = climb.finishPoint;
                let mergedClimb = CourseRange.createWithStartFinish(this, startPoint, finishPoint);
                result.push(mergedClimb);
            } else {
                result.push(climb);
            }
        }

        return result;
    }

    /**
     * 배열의 마지막 점이 climb의 정상이라고 가정 했을 때, 이 climb의 시작점을 찾는다.
     * points의 마지막 점이 climb의 정상이 아닐 경우에는 올바른 값을 반환하지 않을것이다.
     * @param points 마지막 점은 climb의 정상 지점이어야한다.
     * @deprecated
     */
    findStartOfClimb(points: Point[]): Point | null {
        if (!points.length) {
            return null;
        }

        points = points.reverse(); // 배열을 뒤집어서 다운힐의 마지막 지점을 구하는 방식으로 생각한다.
        let summit = points[0];
        let climbStartCandidatePoint: Point | null = null;

        for (let j = 1; j < points.length; j++) {
            let dPoint = points[j];
            let distance = summit.distanceFromCourseStart - dPoint.distanceFromCourseStart; // 배열이 뒤집혀있음을 고려
            if (distance < this.minClimbLengthMeter) continue; // 클라임의 시작점은 클라임의 최소 거리보다 이전에 있어야 한다.

            climbStartCandidatePoint = dPoint;
            // climbStart로 판단된 점 이전의 점을 탐색하여 최저고도 지점을 탐색
            // 최저고도 지점 이후 500m 이상 이동간 상승하지 않을 경우 최저고도 지점을 climbStart로 지정
            for (let k = j + 1; k < points.length; k++) {
                let kPoint = points[k];
                if (climbStartCandidatePoint.elevation > kPoint.elevation) {
                    // 더 낮은 지점이 나오면 climbStart point 교체
                    climbStartCandidatePoint = kPoint;
                } else {
                    // 최저고도 후보 지점 이전 500m 이상 이동간 최저고도 후보 이하 지점 없거나,
                    // 또는 배열의 첫 지점까지 탐색이 되었다면, climbStart point로 등록.
                    var climbDistance = climbStartCandidatePoint.distanceFromCourseStart - kPoint.distanceFromCourseStart; // 배열이 뒤집혀있음을 고려
                    let isLastPoint = k >= points.length - 1;
                    if (climbDistance > 500 || isLastPoint) {
                        return climbStartCandidatePoint;
                    }
                }
            }
        }

        return points[points.length - 1];
    }

    /**
     * distance에 해당하는 가상의 Point를 생성해 반환한다.
     * @param distance
     */
    getVirtualPointByDistance(distance: number): Point | null {
        this.updateTotalValues();

        let points = this.allPoints();
        if (points.length < 2) {
            return null;
        }

        for (let i = 1; i < points.length; i++) {
            let point1 = points[i - 1];
            let point2 = points[i];
            let virtualPoint = Point.createVirtualPointBetween(point1, point2, distance);
            if (virtualPoint) {
                return virtualPoint;
            }
        }

        return null;
    }

    /**
     * 거리 기준 start ~ end에 속하는 점들을 반환한다.
     * 첫번째와 마지막 점은 virtual point일수도 있다.
     * @param start
     * @param end
     */
    getPointsBetween(start: number, finish: number): Point[] {
        let points = this.allPoints();
        if (points.length < 2) {
            return [];
        }

        let startPoint = start <= points[0].distanceFromCourseStart ? points[0] : this.getVirtualPointByDistance(start);
        let finishPoint = finish >= points[points.length - 1].distanceFromCourseStart ? points[points.length - 1] : this.getVirtualPointByDistance(finish);

        let result: Point[] = [startPoint];
        for (let point of points) {
            if (start < point.distanceFromCourseStart && point.distanceFromCourseStart < finish) {
                result.push(point);
            }
        }
        result.push(finishPoint);
        return result;
    }

    insertPointBefore(newPoint: Point, point: Point): boolean {
        for (let section of this.sections) {
            for (let i = 0; i < section.points.length; i++) {
                let pointOfSection = section.points[i];
                if (point == pointOfSection) {
                    section.points.splice(i, 0, newPoint);
                    return true;
                }
            }
        }

        return false;
    }

    isReadPermitted(user: User): boolean {
        if (this.permission !== CoursePermission.PRIVATE) {
            // public course
            return true;
        }

        if (this.userId && this.userId === user.id) {
            // course owner
            return true;
        }

        if (user.isSuperuser) {
            // superuser
            return true;
        }

        return false;
    }

    /**
     * 특정 지점의 고도와 경사도를 추정하여 반환
     * @param x
     */
    getElevationAndSlopeOfPosition(x: number): number[] | null {
        let allPoints = this.allPoints();
        let index = 0;
        for (let point of allPoints) {
            if (point.distanceFromCourseStart >= x) {
                if (index == 0) {
                    break;
                }

                let pPoint = allPoints[index - 1];
                let dDistance = point.distanceFromCourseStart - pPoint.distanceFromCourseStart;
                if (dDistance == 0) {
                    break;
                }

                let dElevation = point.elevation - pPoint.elevation;

                return [pPoint.elevation + dElevation, dElevation / dDistance];
            }
            index++;
        }

        return null;
    }

    /**
     * 특정 지점 바로 이후에 나오는 Point를 반환
     * @param x
     */
    getNextPointOfPosition(x: number): Point | null {
        let allPoints = this.allPoints();
        for (let point of allPoints) {
            if (point.distanceFromCourseStart > x) {
                return point;
            }
        }

        return null;
    }

    static allCoursePermissions = [CoursePermission.ALL_PUBLIC_READ_ONLY, CoursePermission.LINK_PUBLIC_READ_ONLY, CoursePermission.PRIVATE];

    static defaultCoursePermission = CoursePermission.ALL_PUBLIC_READ_ONLY;

    static getCoursePermissionName(permission: CoursePermission): string {
        switch (permission) {
            case CoursePermission.PRIVATE:
                return Resources.text.course_permission_private;
            case CoursePermission.LINK_PUBLIC_READ_ONLY:
                return Resources.text.course_permission_link_public_read_only;
            case CoursePermission.ALL_PUBLIC_READ_ONLY:
                return Resources.text.course_permission_all_public_read_only;
        }
    }

    static getCoursePermissionDescription(permission: CoursePermission): string {
        switch (permission) {
            case CoursePermission.PRIVATE:
                return Resources.text.course_permission_description_private;
            case CoursePermission.LINK_PUBLIC_READ_ONLY:
                return Resources.text.course_permission_description_link_public_read_only;
            case CoursePermission.ALL_PUBLIC_READ_ONLY:
                return Resources.text.course_permission_description_all_public_read_only;
        }
    }
}

export class CourseExtra {
    isVisibleStartInElevationChart?: boolean;
    isVisibleFinishInElevationChart?: boolean;

    static defaultVisibleStartInElevationChart = true;
    static defaultVisibleFinishInElevationChart = true;

    static fromJson(json: any | null): CourseExtra | null {
        if (typeof json === 'string') {
            try {
                json = JSON.parse(json);
            } catch (error) {
                json = null;
            }
        }

        if (!json) {
            return null;
        }

        let result = new CourseExtra();
        result.isVisibleStartInElevationChart = json['isVisibleStartInElevationChart'];
        result.isVisibleFinishInElevationChart = json['isVisibleFinishInElevationChart'];
        return result;
    }

    toJson(): any {
        return {
            isVisibleStartInElevationChart: this.isVisibleStartInElevationChart,
            isVisibleFinishInElevationChart: this.isVisibleFinishInElevationChart,
        };
    }

    clone(): CourseExtra {
        return CourseExtra.fromJson(this.toJson());
    }
}

/**
 * 태그와 코스 갯수를 나타냄
 */
export class TagCourseCount {
    tag: string;
    courseCount: number = 0;

    /**
     * 한글 자모음 분리된
     */
    disassembledTag: string;

    static fromJson(json: any | null): TagCourseCount | null {
        if (typeof json === 'string') {
            try {
                json = JSON.parse(json);
            } catch (error) {
                json = null;
            }
        }

        if (!json) {
            return null;
        }

        let result = new TagCourseCount();
        result.tag = json['tag'] || '';
        result.courseCount = json['courseCount'] || 0;
        result.disassembledTag = Hangul.disassembleToString(result.tag);

        return result;
    }

    toJson(): any {
        return {
            tag: this.tag,
            courseCount: this.courseCount,
        };
    }

    clone(): TagCourseCount {
        return TagCourseCount.fromJson(this.toJson());
    }
}

/**
 * 코스 상에 존재하는 특정한 구간을 표현하는데 사용
 */
export class CourseRange {
    startPoint?: Point;
    finishPoint?: Point;
    elevationGain = 0;
    lengthMeter: number;
    averageSlope: number;

    toJson(): any {
        return {
            startPoint: this.startPoint?.toJson(),
            finishPoint: this.finishPoint?.toJson(),
            lengthMeter: this.lengthMeter,
            elevationGain: this.elevationGain,
            averageSlope: this.averageSlope,
        };
    }

    static fromJson(json: any): CourseRange | null {
        if (!json) {
            return null;
        }

        let courseRange = new CourseRange();
        courseRange.startPoint = Point.fromJson(json['startPoint']);
        courseRange.finishPoint = Point.fromJson(json['finishPoint']);
        courseRange.lengthMeter = json['lengthMeter'];
        courseRange.elevationGain = json['elevationGain'];
        courseRange.averageSlope = json['averageSlope'];

        return courseRange;
    }

    private constructor() { }

    static createWithStartFinish(course: Course, startPoint: Point, finishPoint: Point): CourseRange {
        let courseRange = new CourseRange();

        courseRange.startPoint = startPoint;
        courseRange.finishPoint = finishPoint;
        courseRange.lengthMeter = finishPoint.distanceFromCourseStart - startPoint.distanceFromCourseStart;

        let elevationDifference = finishPoint.elevation - startPoint.elevation;
        courseRange.averageSlope = elevationDifference / courseRange.lengthMeter;

        let allPoints = course.allPoints();
        let startIndex = allPoints.indexOf(startPoint);
        let endIndex = allPoints.indexOf(finishPoint);
        let points = allPoints.slice(startIndex, endIndex);

        for (let i = 1; i < points.length; i++) {
            let p1 = points[i - 1];
            let p2 = points[i];
            if (p2.elevation > p1.elevation) {
                courseRange.elevationGain += p2.elevation - p1.elevation;
            }
        }

        return courseRange;
    }

    isEqualTo(other: CourseRange): boolean {
        if (!other) {
            return false;
        }

        if (
            this.startPoint &&
            this.finishPoint &&
            this.startPoint?.distanceFromCourseStart === other.startPoint?.distanceFromCourseStart &&
            this.finishPoint?.distanceFromCourseStart === other.finishPoint?.distanceFromCourseStart
        ) {
            return true;
        }

        return false;
    }

    toString(): string {
        let components = [
            `startPoint=${this.startPoint.distanceFromCourseStart}`,
            `finishPoint=${this.finishPoint.distanceFromCourseStart}`,
            `lengthMeters=${this.lengthMeter}`,
            `elevationGain=${this.elevationGain}`,
            `averageSlope=${this.averageSlope}`,
        ];
        return components.join(',');
    }

    climbCategory(): number | null {
        let value = this.lengthMeter * (this.averageSlope * 100);
        if (value <= 8000) {
            return null;
        } else if (value <= 16000) {
            return 4;
        } else if (value <= 32000) {
            return 3;
        } else if (value <= 64000) {
            return 2;
        } else if (value <= 80000) {
            return 1;
        } else {
            return 0;
        }
    }

    climbCategoryWaypointType(): WaypointType | null {
        let climbCategory = this.climbCategory();
        if (isNothing(climbCategory)) {
            return null;
        }

        let climbWaypointType: WaypointType | null = null;
        switch (climbCategory) {
            case 0:
                climbWaypointType = WaypointType.CategoryHC;
                break;
            case 1:
                climbWaypointType = WaypointType.Category1;
                break;
            case 2:
                climbWaypointType = WaypointType.Category2;
                break;
            case 3:
                climbWaypointType = WaypointType.Category3;
                break;
            case 4:
                climbWaypointType = WaypointType.Category4;
                break;
        }

        return climbWaypointType;
    }
}

export class Section {
    static autoIncrementClientId = 0;

    /**
     * 클라이언트에서만 임시로 사용하는 id 값
     */
    clientId: number;
    id?: string;
    name?: string;
    points: Point[] = [];

    lengthMeter = 0;
    totalElevationGain = 0;

    private constructor() {
        this.clientId = Section.newClientId();
    }

    static newClientId(): number {
        return ++this.autoIncrementClientId;
    }

    static create(name?: string): Section {
        let section = new Section();
        section.clientId = Section.newClientId();
        section.name = name;
        return section;
    }

    toJson(): any {
        return {
            id: this.id,
            name: this.name,
            points: Point.toObjectArray(this.points),
        };
    }

    static fromJson(json: any): Section | null {
        if (!json) {
            return null;
        }

        let section = new Section();
        section.id = json['id'];
        section.name = json['name'];

        let pointElements = (json['points'] || []) as any[][];
        section.points = Point.fromObjectArray(pointElements);
        return section;
    }

    clone(): Section {
        return Section.fromJson(this.toJson());
    }

    updateTotalValues() {
        let lengthMeter = 0;
        let totalElevationGain = 0;
        if (this.points.length >= 2) {
            for (var i = 1; i < this.points.length; i++) {
                let point1 = this.points[i - 1];
                let point2 = this.points[i];
                let distance = Utility.distanceMeterBetween(point1.latitude, point1.longitude, point2.latitude, point2.longitude);
                point2.distanceFromCourseStart = (point1.distanceFromCourseStart || 0) + distance;
                lengthMeter += distance;

                let elevationGain = (point2.elevation || 0) - (point1.elevation || 0);
                if (elevationGain > 0) {
                    totalElevationGain += elevationGain;
                }
            }
        }

        this.lengthMeter = lengthMeter;
        this.totalElevationGain = totalElevationGain;
    }

    /**
     * 좌표를 이용해 섹션 상에 가상의 점을 가져온다.
     * points 에는 없지만, 선 위에 있는 존재하는 특정한 지점에 대한 처리를 할 때 사용한다.
     * @param latitude
     * @param longitude
     * @param distance
     */
    virtualPointInfoByCoord(latitude: number, longitude: number): VirtualPointInfo | null {
        return Point.virtualPointInfo(latitude, longitude, this.points);
    }

    virtualPointInfoByDistance(distance: number): VirtualPointInfo | null {
        for (let i = 1; i < this.points.length; i++) {
            let pPoint = this.points[i - 1];
            let point = this.points[i];
            let newPoint = Point.createVirtualPointBetween(pPoint, point, distance);
            if (newPoint) {
                return { point: newPoint, indexToInsert: i };
            }
        }
        return null;
    }
}

export interface VirtualPointInfo {
    point: Point;
    indexToInsert: number;
}

export class Point {
    id?: number;
    sectionId?: number; // 과거버젼 대응용
    sectionIndex?: number;
    pointIndex?: number;
    latitude: number;
    longitude: number;
    elevation?: number;
    originalElevation?: number;

    /**
     * 고도 데이터가 OSM을 통해 조회된 것인지 여부
     */
    isOriginalElevationFromOSM?: boolean;

    instructionType?: InstructionType;
    waypoint?: Waypoint;
    date?: number; // milliseconds

    /**
     * 미터 단위이다.
     */
    distanceFromCourseStart?: number;

    /**
     * 경로 탐색을 위해 잠시 추가된 point인지 여부
     */
    isForLoadDirection = false;

    constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    toJson(): any {
        return {
            id: this.id,
            sectionId: this.sectionId,
            sectionIndex: this.sectionIndex,
            latitude: Math.floor(this.latitude * 1000000) / 1000000, // 소수점 이하 6 자리만 남긴다.
            longitude: Math.floor(this.longitude * 1000000) / 1000000, // 소수점 이하 6 자리만 남긴다.
            elevation: Math.floor(this.elevation * 100) / 100, // 소수점 이하 2 자리만 남긴다.
            originalElevation: !isNothing(this.originalElevation)
                ? Math.floor(this.originalElevation * 100) / 100 // 소수점 이하 2 자리만 남긴다.
                : null,
            isOriginalElevationFromOSM: this.isOriginalElevationFromOSM,
            instruction: this.instructionType,
            waypoint: this.waypoint?.toJson(),
            date: this.date,
        };
    }

    static fromJson(json: any): Point | null {
        if (!json) {
            return null;
        }

        let point = new Point(json['latitude'], json['longitude']);
        point.id = json['id'];
        point.sectionId = json['sectionId'];
        point.sectionIndex = json['sectionIndex'];
        point.pointIndex = json['pointIndex'];
        point.elevation = json['elevation'];
        point.originalElevation = json['originalElevation'];
        point.isOriginalElevationFromOSM = json['isOriginalElevationFromOSM'];
        point.instructionType = json['instructionType'];
        point.date = Date.parse(json['date']);

        let waypointJson = json['waypoint'];
        if (waypointJson) {
            point.waypoint = Waypoint.fromJson(waypointJson);
        }

        return point;
    }

    /**
     * array 형태로 된 serialized를 deserialize 한다.
     * @param array
     */
    static fromArray(array: any[]): Point | null {
        if (!array) {
            return null;
        }

        try {
            // 새로운 property가 추가될 경우, 반드시 맨 마지막에 추가해야한다.
            let i = 0;
            let point = new Point(array[i++], array[i++]);
            point.elevation = array[i++];
            point.originalElevation = array[i++];
            point.id = array[i++];
            point.sectionIndex = array[i++];
            point.waypoint = Waypoint.fromJson(array[i++]);
            point.instructionType = array[i++];
            point.sectionId = array[i++];
            point.isOriginalElevationFromOSM = array[i++];

            return point;
        } catch (error) {
            Log.e(error);
            return null;
        }
    }

    /**
     * 용량을 줄이기 위해 json object의 형태가 아닌 array 형태로 serialize 한다.
     */
    toArray(): any[] {
        // 새로운 property가 추가될 경우, 반드시 맨 마지막에 추가해야한다.
        return [
            Math.floor(this.latitude * 1000000) / 1000000, // 소수점 이하 6 자리만 남긴다.
            Math.floor(this.longitude * 1000000) / 1000000, // 소수점 이하 6 자리만 남긴다.
            Math.floor(this.elevation * 100) / 100, // 소수점 이하 2 자리만 남긴다.
            !isNothing(this.originalElevation)
                ? Math.floor(this.originalElevation * 100) / 100 // 소수점 이하 2 자리만 남긴다.
                : null,
            this.id,
            this.sectionIndex,
            this.waypoint?.toJson(),
            this.instructionType,
            this.sectionId,
            this.isOriginalElevationFromOSM,
        ];
    }

    static fromObjectArray(array: any[]): Point[] {
        return array
            .map((element) => {
                if (!isNothing(element.latitude)) {
                    // latitude property가 있다면 json object의 형태인 것이다.
                    return Point.fromJson(element);
                } else {
                    return Point.fromArray(element);
                }
            })
            .filter((point) => {
                return point != null;
            });
    }

    static toObjectArray(points: Point[]): any[][] {
        return points.map((point) => {
            return point.toArray();
        });
    }

    clone(): Point {
        return Point.fromJson(this.toJson());
    }

    static createVirtualPointBetween(point1: Point, point2: Point, distance: number): Point | null {
        if (point1.sectionIndex != point2.sectionIndex) {
            // section이 다를 경우, 두 점의 사이에는 가상 점을 생성할 수 없다.
            return null;
        }

        if (distance < point1.distanceFromCourseStart) {
            return null;
        }

        if (distance > point2.distanceFromCourseStart) {
            return null;
        }

        let ratio = (distance - point1.distanceFromCourseStart) / (point2.distanceFromCourseStart - point1.distanceFromCourseStart);
        let dLatitude = point2.latitude - point1.latitude;
        let dLongitude = point2.longitude - point1.longitude;
        let dElevation = point2.elevation - point1.elevation;
        let latitude = point1.latitude + dLatitude * ratio;
        let longitude = point1.longitude + dLongitude * ratio;
        let elevation = point1.elevation + dElevation * ratio;
        let newPoint = new Point(latitude, longitude);

        newPoint.sectionIndex = point1.sectionIndex;
        newPoint.elevation = elevation;
        newPoint.distanceFromCourseStart = distance;

        if (!isNothing(point1.originalElevation) && !isNothing(point2.originalElevation)) {
            let dOriginalElevation = point2.originalElevation - point2.originalElevation;
            let originalElevation = point1.originalElevation + dOriginalElevation * ratio;
            newPoint.originalElevation = originalElevation;
        }

        return newPoint;
    }

    /**
     * 전달된 위, 경도가 이 경로상에 위치할 경우, 두가지를 반환한다.
     * 1. 새로 생성된 Point. 이 Point에는 예측되는 elevation 값이 들어간다.
     * 2. 생성된 Point를 사용할 경우, points의 몇번째 index에 삽입해야하는지.
     *
     * @param latitude
     * @param longitude
     * @param points
     */
    static virtualPointInfo(latitude: number, longitude: number, points: Point[]): VirtualPointInfo | null {
        if (points.length < 2) {
            return null;
        }

        // point의 전, 후 점을 구한다. (before, after)
        var nearestIndexOfAfterPoint: number = undefined;
        var nearestDistanceOfAfterPoint: number = undefined;
        for (let i = 1; i < points.length; i++) {
            let point1 = points[i - 1];
            let point2 = points[i];
            let pointLineDistance = Utility.pointLineDistance(longitude, latitude, point1.longitude, point1.latitude, point2.longitude, point2.latitude) * 10000;

            if (nearestDistanceOfAfterPoint == undefined || pointLineDistance < nearestDistanceOfAfterPoint) {
                nearestDistanceOfAfterPoint = pointLineDistance;
                nearestIndexOfAfterPoint = i;
            }
        }

        if (nearestIndexOfAfterPoint == undefined) {
            return null;
        }

        let pointOfBefore = points[nearestIndexOfAfterPoint - 1];
        let pointOfAfter = points[nearestIndexOfAfterPoint];

        let distanceFromBefore = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(pointOfBefore.latitude, pointOfBefore.longitude),
            new google.maps.LatLng(latitude, longitude)
        );

        let virtualPoint = this.createVirtualPointBetween(pointOfBefore, pointOfAfter, pointOfBefore.distanceFromCourseStart + distanceFromBefore);

        if (!virtualPoint) {
            return null;
        }

        return {
            point: virtualPoint,
            indexToInsert: nearestIndexOfAfterPoint,
        };
    }
}

export class Waypoint {
    type: WaypointType;
    pointId?: number; // Point와 Waypoint간의 종속관계를 맺는데 필요하다.
    pointIndex?: number; // Point와 Waypoint간의 종속관계를 맺는데 필요하다.
    name?: string;
    note?: string;

    latitude?: number;
    longitude?: number;
    date?: number; // milliseconds

    /**
     * 고도차트에 보여줄지 말지여부, null이나 undefined일 경우에는 기본값(defaultVisibleInElevationChart)을 따르도록 한다.
     */
    isVisibleInElevationChart?: boolean;

    constructor(type: WaypointType, name?: string, note?: string) {
        this.type = type;
        this.name = name;
        this.note = note;
    }

    static fromJson(json: any): Waypoint | null {
        if (!json) {
            return null;
        }

        let type: WaypointType;

        if (typeof json['type'] === 'string') {
            type = WaypointType.getTypeByTag(json['type']);
        } else {
            let typeJson = json['type'];
            let tag = typeJson && typeJson['tag'];
            if (!tag) {
                tag = WaypointType.Generic.tag;
            }
            type = WaypointType.getTypeByTag(tag);
        }

        if (!type) {
            type = WaypointType.Generic;
        }

        let waypoint = new Waypoint(type, json['name'], json['note']);

        waypoint.pointId = json['pointId'];
        waypoint.pointIndex = json['pointIndex'];
        waypoint.latitude = json['latitude'];
        waypoint.longitude = json['longitude'];
        waypoint.date = Date.parse(json['date']);
        waypoint.isVisibleInElevationChart = json['isVisibleInElevationChart'];

        return waypoint;
    }

    static defaultVisibleInElevationChart(type: WaypointType): boolean {
        switch (type) {
            case WaypointType.Left:
            case WaypointType.Right:
            case WaypointType.Straight:
                return false;
            default:
                return true;
        }
    }

    toJson(): any {
        return {
            type: this.type.toJson(),
            pointId: this.pointId,
            name: this.name,
            note: this.note,
            isVisibleInElevationChart: this.isVisibleInElevationChart,
        };
    }

    clone(): Waypoint {
        return Waypoint.fromJson(this.toJson());
    }
}

/**
 * 지시 사항
 * https://github.com/GIScience/openrouteservice-docs#instruction-types
 */
export enum InstructionType {
    LEFT = 0,
    RIGHT = 1,
    SHARP_LEFT = 2,
    SHARP_RIGHT = 3,
    SLIGHT_LEFT = 4,
    SLIGHT_RIGHT = 5,
}

export class WaypointType {
    name: string;
    tag: string;
    typeForGarmin: string;

    constructor(name: string, tag: string, typeForGarmin: string) {
        this.name = name;
        this.tag = tag;
        this.typeForGarmin = typeForGarmin;
    }

    static fromJson(json: any): WaypointType | null {
        if (!json) {
            return null;
        }

        return WaypointType.getTypeByTag(json['tag']) ||
            WaypointType.getTypeByGarmin(json['typeForGarmin']);
    }

    toJson(): any {
        return {
            name: this.name,
            tag: this.tag,
            typeForGarmin: this.typeForGarmin
        };
    }

    clone(): WaypointType {
        return WaypointType.fromJson(this.toJson());
    }

    get imageName(): string {
        return this.tag.replace(/\s+/g, '_').toLowerCase();
    }

    get relativeImageSrc(): string {
        return `waypoint_icon/${this.imageName}.png`;
    }

    get relativePinImageSrc(): string {
        return `waypoint_icon/pin/${this.imageName}.png`;
    }

    get isDirection(): boolean {
        switch (this.tag) {
            case WaypointType.Right.tag:
            case WaypointType.Left.tag:
            case WaypointType.Straight.tag:
                return true;
            default:
                return false;
        }
    }

    static Generic = new WaypointType(Resources.text.waypoint_type_generic, 'Generic', 'GENERIC');
    static Summit = new WaypointType(Resources.text.waypoint_type_summit, 'Summit', 'SUMMIT');
    static Valley = new WaypointType(Resources.text.waypoint_type_valley, 'Valley', 'VALLEY');
    static Water = new WaypointType(Resources.text.waypoint_type_water, 'Water', 'WATER');
    static Food = new WaypointType(Resources.text.waypoint_type_food, 'Food', 'FOOD');
    static Danger = new WaypointType(Resources.text.waypoint_type_danger, 'Danger', 'DANGER');
    static Left = new WaypointType(Resources.text.waypoint_type_left, 'Left', 'LEFT');
    static Right = new WaypointType(Resources.text.waypoint_type_right, 'Right', 'RIGHT');
    static Straight = new WaypointType(Resources.text.waypoint_type_straight, 'Straight', 'STRAIGHT');
    static FirstAid = new WaypointType(Resources.text.waypoint_type_first_aid, 'First Aid', 'FIRST AID');
    static Category4 = new WaypointType(Resources.text.waypoint_type_category_4, '4th Category', 'FOURTH CATEGORY');
    static Category3 = new WaypointType(Resources.text.waypoint_type_category_3, '3rd Category', 'THIRD CATEGORY');
    static Category2 = new WaypointType(Resources.text.waypoint_type_category_2, '2nd Category', 'SECOND CATEGORY');
    static Category1 = new WaypointType(Resources.text.waypoint_type_category_1, '1st Category', 'FIRST CATEGORY');
    static CategoryHC = new WaypointType(Resources.text.waypoint_type_category_h, 'Hors Category', 'HORS CATEGORY');
    static Sprint = new WaypointType(Resources.text.waypoint_type_sprint, 'Sprint', 'SPRINT');
    static Start = new WaypointType(Resources.text.course_start, 'Start', 'START'); // not selectable
    static Finish = new WaypointType(Resources.text.course_finish, 'Finish', 'FINISH'); // not selectable

    static types: WaypointType[] = [
        WaypointType.Generic,
        WaypointType.Summit,
        WaypointType.Valley,
        WaypointType.Water,
        WaypointType.Food,
        WaypointType.Danger,
        WaypointType.Left,
        WaypointType.Right,
        WaypointType.Straight,
        WaypointType.FirstAid,
        WaypointType.Category4,
        WaypointType.Category3,
        WaypointType.Category2,
        WaypointType.Category1,
        WaypointType.CategoryHC,
        WaypointType.Sprint,
        WaypointType.Start,
        WaypointType.Finish,
    ];

    static selectableTypes: WaypointType[] = [
        WaypointType.Generic,
        WaypointType.Summit,
        WaypointType.Valley,
        WaypointType.Water,
        WaypointType.Food,
        WaypointType.Danger,
        WaypointType.Left,
        WaypointType.Right,
        WaypointType.Straight,
        WaypointType.FirstAid,
        WaypointType.Category4,
        WaypointType.Category3,
        WaypointType.Category2,
        WaypointType.Category1,
        WaypointType.CategoryHC,
        WaypointType.Sprint,
    ];

    static getTypeByTag(tag: string): WaypointType | null {
        if (!tag) {
            return null;
        }

        let filtered = this.types.filter((type) => {
            return type.tag.toLowerCase() == tag.toLowerCase();
        });

        if (!filtered.length) {
            return null;
        }

        return filtered[0];
    }

    static getTypeByGarmin(typeForGarmin: string): WaypointType | null {
        if (!typeForGarmin) {
            return null;
        }

        let filtered = this.types.filter((type) => {
            return type.tag.toLowerCase() == typeForGarmin.toLowerCase();
        });

        if (!filtered.length) {
            return null;
        }

        return filtered[0];
    }
}

export class Bounds {
    north: number;
    east: number;
    south: number;
    west: number;

    constructor(north: number, east: number, south: number, west: number) {
        this.north = north;
        this.east = east;
        this.south = south;
        this.west = west;
    }

    static fromPoints(points: Point[]): Bounds {
        let north: number;
        let east: number;
        let south: number;
        let west: number;

        points.forEach((point) => {
            if (isNothing(north) || point.latitude > north) {
                north = point.latitude;
            }

            if (isNothing(east) || point.longitude > east) {
                east = point.longitude;
            }

            if (isNothing(south) || point.latitude < south) {
                south = point.latitude;
            }

            if (isNothing(west) || point.longitude < east) {
                west = point.longitude;
            }
        });

        return new Bounds(north, east, south, west);
    }
}

/**
 * 현재 편집 중인 course 상에 존재하지 않지만 DB에는 존재하는 특정 구간을 표현할 때 사용
 */
export class MapSection {
    id?: number;
    courseId?: number;
    name?: string;
    startPointId?: number;
    finishPointId?: number;
    points?: Point[];
    elevationGain = 0;
    lengthMeter: number;
    averageSlope: number;

    get bounds(): Bounds {
        return Bounds.fromPoints(this.points);
    }

    static fromJson(json: any): MapSection | null {
        if (!json) {
            return null;
        }

        let mapSection = new MapSection();
        mapSection.id = json['id'];
        mapSection.courseId = json['courseId'];
        mapSection.name = json['name'];
        mapSection.startPointId = json['startPointId'];
        mapSection.finishPointId = json['finishPointId'];
        mapSection.points = (json['points'] as any[])?.map((item) => {
            return Point.fromJson(item);
        });
        mapSection.elevationGain = json['elevationGain'];
        mapSection.lengthMeter = json['lengthMeter'];
        mapSection.averageSlope = json['averageSlope'];

        return mapSection;
    }

    toJson(): any {
        return {
            id: this.id,
            courseId: this.courseId,
            name: this.name,
            startPointId: this.startPointId,
            finishPointId: this.finishPointId,
            points: this.points?.map((point) => {
                return point.toJson();
            }),
            elevationGain: this.elevationGain,
            lengthMeter: this.lengthMeter,
            averageSlope: this.averageSlope,
        };
    }

    isEqualTo(other: MapSection) {
        if (!other) {
            return false;
        }

        if (this.startPointId && this.finishPointId && this.startPointId === other.startPointId && this.finishPointId === other.finishPointId) {
            return true;
        }

        return false;
    }

    toString(): string {
        return `id=${this.id},name=${this.name || ''},length=${this.lengthMeter.toFixed(1)},elevation=${this.elevationGain.toFixed(1)},slope=${this.averageSlope.toFixed(3)}`;
    }

    static fromCourseRange(courseRange: CourseRange, course: Course): MapSection {
        let mapSection = new MapSection();
        mapSection.courseId = course.id;
        mapSection.startPointId = courseRange.startPoint?.id;
        mapSection.finishPointId = courseRange.finishPoint?.id;
        mapSection.elevationGain = courseRange.elevationGain;
        mapSection.lengthMeter = courseRange.lengthMeter;
        mapSection.averageSlope = courseRange.averageSlope;
        mapSection.points = course.allPoints().filter((point) => {
            return point.id >= courseRange.startPoint.id && point.id <= courseRange.finishPoint.id;
        });
        return mapSection;
    }
}

/**
 * 코스 목록을 조회하는데 사용되는 Configuration
 */
export class CourseListConfiguration {
    page: number;
    pageSize: number;
    loadPublic: boolean;
    userNick?: string;
    searchKeyword?: string;
    sortColumn: string | 'name' | 'lengthMeter' | 'totalElevationGain' | 'createdTimestamp' | 'modifiedTimestamp';
    isSortAsc: boolean;
    loadAllIfSuperuser?: boolean;

    /**
     * 정렬을 하는데 허용되는 column 명들
     */
    static readonly allowedColumNames = ['name', 'lengthMeter', 'totalElevationGain', 'createdTimestamp', 'modifiedTimestamp'];

    static defaultConfiguration(): CourseListConfiguration {
        let result = new CourseListConfiguration();
        result.page = 1;
        result.pageSize = 10;
        result.loadPublic = false;
        result.sortColumn = 'modifiedTimestamp';
        result.isSortAsc = CourseListConfiguration.defaultIsSortOrderAsc('modifiedTimestamp');
        return result;
    }

    /**
     * URL을 분석하여 생성
     * @param urlString
     */
    static parseUrlString(urlString: string): CourseListConfiguration | null {
        let result = CourseListConfiguration.defaultConfiguration();

        let url = new URL(urlString);
        let pathComponents = url.pathname.split('/');
        if (pathComponents.length >= 3 && pathComponents[2] == 'courseList') {
            // do nothing
        } else {
            return null;
        }

        let params = url.searchParams;
        let listType = 'public';
        if (pathComponents.length >= 4) {
            listType = pathComponents[3].toLowerCase();
        }

        if (listType == 'public') {
            result.loadPublic = true;
        } else if (listType == 'user' && pathComponents.length >= 5) {
            result.userNick = decodeURIComponent(pathComponents[4]);
        }

        let searchKeyword = params.get('searchKeyword');
        if (searchKeyword) {
            searchKeyword = decodeURIComponent(searchKeyword).trim();
        }
        if (searchKeyword && searchKeyword.length) {
            result.searchKeyword = searchKeyword;
        }

        return result;
    }

    /**
     * 컬럼에 대한 정렬기준의 기본값이 오름차순인지 여부
     * @param columnName
     */
    static defaultIsSortOrderAsc(columnName: string): boolean {
        switch (columnName) {
            case 'name':
                return true;
            default:
                return false;
        }
    }

    get isMyCourseList(): boolean {
        if (this.userNick) {
            return false;
        }

        if (this.loadPublic) {
            return false;
        }

        if (this.loadAllIfSuperuser) {
            return false;
        }

        return true;
    }

    titleForDialog(textResources: TextResources): string {
        let title = textResources.course_list_my;
        if (this.userNick) {
            title = sprintf(textResources.course_list_user_format, this.userNick);
        } else if(this.loadAllIfSuperuser) {
            title = textResources.course_list_public;
        } else if (this.loadPublic) {
            title = textResources.course_list_public;
        }

        let searchKeyword = this.searchKeyword;
        if (searchKeyword) {
            title += ' - ';
            title += sprintf(textResources.course_list_suffix_search_result_format, searchKeyword);
        }

        return title;
    }

    titleForDocument(textResources: TextResources): string {
        return `${this.titleForDialog(textResources)} - ${textResources.ridingazua_editor_title}`;
    }

    /**
     * 해당 코스 목록 화면으로 바로 진입가능한 URL
     */
    get urlString(): string {
        let result = Statics.courseListUrlString();

        if (this.loadPublic) {
            result += '/public';
        } else if (this.userNick) {
            result += `/user/${encodeURIComponent(this.userNick)}`;
        } else {
            result += `/my`;
        }

        if (this.searchKeyword && this.searchKeyword.length) {
            result += `?searchKeyword=${encodeURIComponent(this.searchKeyword)}`;
        }

        return result;
    }
}

/**
 * 광고 게시 위치
 */
export enum AdvertisementPosition {
    NEW_DIALOG = 1,
    SAVE_DIALOG = 2,
    IMPORT_DIALOG = 3,
    EXPORT_DIALOG = 4,
    LOGIN_DIALOG = 5,
    AD_PREVIEW_DIALOG = 1000,
}

/**
 * 광고
 */
export class Advertisement {
    id: number;
    isShowing: boolean = false;
    position?: AdvertisementPosition;
    imageFileId?: number;
    imageWidth?: number;
    imageHeight?: number;

    /**
     * 배경색상
     */
    backgroundColor?: string;
    startTimestamp?: number;
    link?: string;

    /**
     * 미리보기를 위해 로컬에서만 사용하는 property
     */
    imageUrlForPreview?: string;

    static dateFormat = 'yyyy.mm.dd HH:MM';

    static fromJson(json: any): Advertisement | null {
        if (!json) {
            return null;
        }

        let ad = new Advertisement();
        ad.id = json['id'];
        ad.isShowing = json['isShowing'];
        ad.position = json['position'];
        ad.imageFileId = json['imageFileId'];
        ad.imageWidth = json['imageWidth'];
        ad.imageHeight = json['imageHeight'];
        ad.backgroundColor = json['backgroundColor'];
        ad.startTimestamp = json['startTimestamp'];
        ad.link = json['link'];

        return ad;
    }

    toJson(): any {
        return {
            id: this.id,
            position: this.position,
            isShowing: this.isShowing,
            imageFileId: this.imageFileId,
            imageWidth: this.imageWidth,
            imageHeight: this.imageHeight,
            backgroundColor: this.backgroundColor,
            startTimestamp: this.startTimestamp,
            link: this.link,
        };
    }

    clone(): Advertisement {
        return Advertisement.fromJson(this.toJson());
    }

    invalidMessage(ignoreImage: boolean = true): string | null {
        let url: URL = null;
        try {
            url = new URL(this.link);
        } catch (e) {
            // do nothing
        }

        if (!url) {
            return Resources.text.ad_manager_invalid_link;
        } else if (!ignoreImage && !this.imageFileId) {
            return Resources.text.ad_manager_image_not_found;
        } else if (!this.position) {
            return Resources.text.ad_manager_invalid_position;
        }

        return null;
    }

    sourceImageUrl(): string | null {
        if (!this.imageFileId) {
            return null;
        }

        return Statics.uploadedFile(this.imageFileId);
    }

    imageUrl(width: number, scale: number): string | null {
        if (!this.imageFileId) {
            return null;
        }

        return Statics.uploadedImageFile(this.imageFileId, width, scale);
    }

    get thumbnailImageUrl(): string | null {
        if (!this.imageFileId) {
            return null;
        }

        return Statics.uploadedFile(this.imageFileId, true);
    }

    get formattedStartTime(): string | null {
        if (!this.startTimestamp) {
            return null;
        }

        let date = new Date(this.startTimestamp * 1000);
        return dateformat(date, Advertisement.dateFormat);
    }

    /**
     * 현재 노출중인 광고의 isShowing 값을 true로 설정한다.
     * @param ads
     */
    static setShowing(ads: Advertisement[]) {
        ads.forEach((ad) => {
            let currentTimestamp = new Date().getTime() / 1000;
            ad.isShowing = ad.startTimestamp < currentTimestamp;
        });
    }

    static allPositions = [
        AdvertisementPosition.NEW_DIALOG,
        AdvertisementPosition.SAVE_DIALOG,
        AdvertisementPosition.IMPORT_DIALOG,
        AdvertisementPosition.EXPORT_DIALOG,
        AdvertisementPosition.LOGIN_DIALOG,
    ];

    static nameForPosition(position: AdvertisementPosition): string {
        switch (position) {
            case AdvertisementPosition.NEW_DIALOG:
                return Resources.text.advertisement_position_new_dialog;
            case AdvertisementPosition.SAVE_DIALOG:
                return Resources.text.advertisement_position_save_dialog;
            case AdvertisementPosition.IMPORT_DIALOG:
                return Resources.text.advertisement_position_import_dialog;
            case AdvertisementPosition.EXPORT_DIALOG:
                return Resources.text.advertisement_position_export_dialog;
            case AdvertisementPosition.LOGIN_DIALOG:
                return Resources.text.advertisement_position_login_dialog;
        }

        return Resources.text.advertisement_position_unknown;
    }
}

/**
 * 업로드된 파일 정보
 */
export class UploadedFileInfo {
    id: number;
    size: number;
    mimetype?: string;
    originalName: string;
    uploadedTimestamp: number;
    imageWidth?: number;
    imageHeight?: number;

    static fromJson(json: any): UploadedFileInfo | null {
        if (!json) {
            return null;
        }

        let file = new UploadedFileInfo();
        file.id = json['id'];
        file.size = json['size'];
        file.mimetype = json['mimetype'];
        file.originalName = json['originalName'];
        file.uploadedTimestamp = json['uploadedTimestamp'];
        file.imageWidth = json['imageWidth'];
        file.imageHeight = json['imageHeight'];

        return file;
    }

    toJson(): any {
        return {
            id: this.id,
            size: this.size,
            mimetype: this.mimetype,
            originalName: this.originalName,
            uploadedTimestamp: this.uploadedTimestamp,
            imageWidth: this.imageWidth,
            imageHeight: this.imageHeight,
        };
    }

    clone(): UploadedFileInfo {
        return UploadedFileInfo.fromJson(this.toJson());
    }
}

export enum AdType {
    LOCAL,
    GOOGLE
}